<template>
  <div class="airdrop-header">
    <div class="header-content-wrapper">
      <BRIcon
        class="airdrop-page-logo"
        height="64"
        icon="binocular"
        width="64"
      />
      <div>
        <div class="title-wrapper">
          <h1 class="header-title">
            AirDrop Calendar
          </h1>
          <BRChip class="airdrop-header__chip" uppercase value="top feature" />
        </div>
        <p class="subtitle">
          Offering calendar listed in date order.
          You can check all items and available ongoing / upcoming coins and tokens
        </p>
      </div>
    </div>
    <div class="search">
      <BRInput
        :value.sync="searchValue"
        append-icon="mdi-close"
        class="search-input"
        placeholder="Airdrop"
        @click:append="searchValue = ''"
      >
        <v-icon class="mr-1" color="var(--default-icon-color)">
          mdi-magnify
        </v-icon>
      </BRInput>
    </div>
  </div>
</template>

<script>
import BRInput from '@/components/BRComponents/BRInput.vue'
import BRChip from '@/components/BRComponents/BRChip.vue'

export default {
  name: 'AirdropHeader',
  components: {
    BRInput,
    BRChip
  },
  props: {
    searchKeyword: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      searchValue: this.searchKeyword
    }
  },
  watch: {
    searchValue() {
      this.$emit('update:searchKeyword', this.searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.airdrop-header {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-gap: 25px;
  grid-template-columns: auto auto;
  padding: 16px 0 37px;
  width: 100%;

  @include breakpoint-reverse(medium) {
    grid-template-columns: 1fr;
    padding: 25px 0;
  }

  @include breakpoint-reverse(small) {
    grid-gap: 28px;
    padding: 15px 0;
  }
}

.header-content-wrapper {
  display: flex;
  align-items: center;
  max-width: 495px;

  .airdrop-page-logo {
    flex-shrink: 0;
    margin-right: 25px;
    width: 64px;
    height: 64px;

    @include breakpoint-reverse(small) {
      margin-right: 13px;
      width: 44px;
      height: 44px;
    }
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    @include breakpoint-reverse(small) {
      flex-wrap: wrap-reverse;
      gap: 4px;
    }
  }

  .header-title {
    margin-right: 14px;
    font-size: var(--font-h1);

    @include breakpoint-reverse(small) {
      margin-right: 11px;
    }
  }

  .airdrop-header__chip[class] {
    padding: 3px 9px 0;
  }

  .subtitle {
    @include subtitle;

    margin-bottom: 6px;

    @include breakpoint-reverse(small) {
      margin-bottom: 0;
    }
  }

  @include breakpoint-reverse(medium) {
    justify-self: center;
  }

  @include breakpoint-reverse(small) {
    padding: 0 9px 0 5px;
  }
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;

  .search-input {
    width: 100%;
    max-width: 200px;

    @include breakpoint-reverse(medium) {
      grid-template-columns: 1fr;
      max-width: 300px;
    }

    @include breakpoint-reverse(small) {
      max-width: 100%;
    }
  }
}
</style>
