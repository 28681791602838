<template>
  <div
    class="airdrop-table-item"
    @click="showItemPreview(item)"
  >
    <div class="item-image">
      <v-avatar size="34">
        <v-img
          :src="getAirdropLogo"
          :style="{ backgroundColor: item.image ? '' : 'var(--tertiary-bg-color)'}"
          alt="airdrop avatar"
        />
      </v-avatar>
    </div>
    <div class="item-title-block">
      <span class="item-title">{{ item.title }}</span>
      <span v-if="item.shortTitle" class="item-subtitle">{{ item.shortTitle }}</span>
      <span v-if="item.currencyName" class="item-label">{{ item.currencyName }}</span>
      <BRPeriodChip :date-end="item.dateEnd" :date-start="item.dateStart" class="item-status-mobile" />
    </div>
    <div class="item-status item-status_tablet">
      <BRPeriodChip :date-end="item.dateEnd" :date-start="item.dateStart" />
    </div>
    <p v-if="item.estValue" class="item-est-value">
      {{ item.estValue }}
    </p>
    <AirdropLike
      :id="item.id"
      class="item-likes"
      :liked="item.isLiked"
      :likes="item.likesAmount"
      @toggleLike="$emit('toggleLike', $event)"
    />
  </div>
</template>

<script>
import { formatDate, timeLeft } from '@/services/date'
import images from '@/mixins/image'
import AirdropLike from '@/components/AirdropLike.vue'
import BRPeriodChip from '@ui/BRPeriodChip.vue'

export default {
  name: 'AirdropTableItem',
  components: {
    BRPeriodChip,
    AirdropLike
  },
  mixins: [images],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    getTimeLeft() {
      return this.timeLeft(new Date(), this.item.dateEnd)
    },
    getAirdropLogo() {
      return this.item.logo || '/img/default-image.webp'
    },
    isDesktop() {
      return window.innerWidth > 1024
    }
  },
  methods: {
    showItemPreview(item) {
      this.$emit('showItemPreview', item)
      if (this.isDesktop) return
      this.$emit('openModal', item)
    },
    timeLeft,
    formatDate
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.airdrop-table-item {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
  grid-template-areas: "image title value like";
  grid-template-columns: 34px minmax(99px, 160px) 1fr 0.3fr;
  margin: 0;
  border-bottom: var(--default-border);
  padding: 15px 0 13px;
  transition: 0.3s background-color ease-in-out;
  cursor: pointer;

  &:hover {
    @include breakpoint(medium) {
      border-radius: var(--border-radius-default);
      background-color: var(--tertiary-bg-color);
    }
  }

  &:last-child {
    border-bottom: none;
  }

  @include breakpoint(medium) {
    grid-gap: initial;
    grid-template-areas: "image title status value like";
    grid-template-columns: 54px 196px repeat(2, 1fr) 0.29fr;
    margin: 0 -25px;
    padding: 17px 25px 12px;
  }
}

.item-image {
  grid-area: image;
}

.item-title-block {
  display: grid;
  grid-area: title;
  grid-gap: 6px 16px;
  grid-template-areas: "title title" "subtitle label";
  width: fit-content;

  .item-title {
    grid-area: title;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  .item-subtitle {
    grid-area: subtitle;
    font-size: var(--font-sm);
    color: var(--secondary-text-color);
  }

  .item-label {
    display: block;
    grid-area: label;
    margin-right: auto;
    border-radius: 7px;
    padding: 3px 5px;
    width: fit-content;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-xs);
    color: var(--secondary-text-color);
    background-color: var(--label-bg-color);

    @include breakpoint(medium) {
      margin-right: 0;
      padding: 3px 14px;
    }
  }

  @include breakpoint(medium) {
    grid-gap: 5px 8px;
    grid-template-areas: "title subtitle" "label label";
  }
}

.item-status {
  grid-area: status;
  font-size: var(--font-base);

  &-mobile {
    display: block;

    @include breakpoint(medium) {
      display: none;
    }
  }

  &_tablet {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }
}

.item-time-and-date {
  grid-area: date;

  .time-left,
  .date {
    display: block;
  }

  .time-left {
    margin-bottom: 5px;
    font-size: var(--font-base);
  }

  .date {
    font-size: var(--font-sm);
    color: var(--secondary-text-color);
  }
}

.item-est-value {
  grid-area: value;
}

.item-likes {
  grid-area: like;
  width: 31px;
}
</style>
