<template>
  <div class="airdrop-main">
    <BRTabs
      :tabs="tabsList"
      class="airdrop-main-tabs"
      tab-width="150"
      @changeActiveTab="filterContent"
    />
    <div v-if="visibleAirdrops.length" class="content-table">
      <div class="table-header">
        <div class="table-header-item table-header-item--project">
          Project
        </div>
        <div class="table-header-item table-header-item--status">
          Status
        </div>
        <div class="table-header-item table-header-item--est-value">
          Est. Value
        </div>
        <div class="table-header-item table-header-item--likes">
          Likes
        </div>
      </div>
      <div
        v-for="airdrop in visibleAirdrops"
        :key="airdrop.id"
        class="table-item-wrapper"
      >
        <AirdropTableItem
          :item="airdrop"
          @showItemPreview="showItemPreview"
          @toggleLike="toggleLike($event, airdrop)"
          @openModal="openModal"
        />
      </div>
      <BRButton
        v-if="isShowSeeMoreAirdropsButton"
        class="show-more"
        color="var(--quaternary-button-bg-color)"
        dark
        width="252"
        @click="currentPage++"
      >
        SHOW MORE
      </BRButton>
    </div>
    <div v-else class="no-airdrops">
      <h3 class="no-airdrops__text">
        No airdrops found
      </h3>
    </div>
  </div>
</template>

<script>
import BRTabs from '@/components/BRComponents/BRTabs.vue'
import AirdropTableItem from '@/components/AirdropTableItem'

export default {
  name: 'AirdropMain',
  components: {
    BRTabs,
    AirdropTableItem
  },
  props: {
    airdrops: {
      type: Array,
      required: true
    },
    upcomingAirdrop: {
      type: Array,
      required: true
    },
    ongoingAirdrop: {
      type: Array,
      required: true
    },
    endedAirdrop: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tabsList: [
        {
          id: 1,
          title: 'Overview',
          icon: 'eye',
          name: ''
        },
        {
          id: 2,
          title: 'Ongoing',
          icon: 'fire',
          name: 'Ongoing'
        },
        {
          id: 3,
          title: 'Upcoming',
          icon: 'calendar',
          name: 'Upcoming'
        },
        {
          id: 4,
          title: 'Ended',
          icon: 'check-circle',
          name: 'Ended'
        }
      ],
      airdropTableItems: [...this.airdrops],
      currentPage: 0,
      pageSize: 10
    }
  },
  computed: {
    visibleAirdrops() {
      return this.airdropTableItems.slice(0, (this.currentPage + 1) * this.pageSize)
    },
    isShowSeeMoreAirdropsButton() {
      return this.visibleAirdrops.length < this.airdropTableItems.length
    }
  },
  watch: {
    airdrops() {
      this.filterContent(this.$route.query.tab)
    }
  },
  methods: {
    filterContent(period) {
      this.currentPage = 0
      switch (period) {
        case 'Upcoming':
          this.airdropTableItems = [...this.upcomingAirdrop]
          break
        case 'Ongoing':
          this.airdropTableItems = [...this.ongoingAirdrop]
          break
        case 'Ended':
          this.airdropTableItems = [...this.endedAirdrop]
          break
        default:
          this.airdropTableItems = [...this.airdrops]
      }

      const previewItem = this.airdropTableItems[0] || {}
      this.showItemPreview(previewItem)
    },
    showItemPreview(item) {
      this.$emit('showItemPreview', item)
    },
    toggleLike(value, airdrop) {
      airdrop.isLiked = value.isLiked
      airdrop.likesAmount = value.likesAmount
    },
    openModal() {
      this.$emit('showModal', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.airdrop-main {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  width: 100%;

  @include breakpoint(large) {
    margin-right: 20px;
  }
}

.airdrop-main-tabs {
  align-self: center;
  margin-bottom: 25px;
}

.table-header {
  display: grid;
  grid-template-columns: 250px repeat(2, 1fr) 0.29fr;
  margin: 28px 0 10px;

  .table-header-item {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    text-align: left;

    &:first-child {
      padding-left: 4px;
    }
  }

  @include breakpoint-reverse(small) {
    grid-template-columns: minmax(150px, 194px) 1fr 0.3fr;
    margin: 26px 0 7px;
    padding-left: 6px;
    gap: 12px;

    .table-header-item {
      &--status {
        display: none;
      }

      &--est-value {
        order: 2;
      }

      &--likes {
        order: 3;
      }
    }
  }
}

.content-table {
  display: grid;
  padding-right: 0;
  padding-left: 0;

  @include breakpoint(large) {
    padding-right: 25px;
  }

  @include breakpoint(medium) {
    padding-left: 30px;
  }
}

.table-item-wrapper {
  border-bottom: 1px solid var(--tertiary-border-color);
  padding: 2px 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.show-more {
  justify-self: center;
  margin-top: 15px;
  border-radius: 10px;

  &::v-deep .button-text {
    color: var(--secondary-text-color);
  }
}

.no-airdrops {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.no-airdrops__text {
  font-size: var(--font-h3);
  text-align: center;
  color: var(--secondary-text-color);
}
</style>
