<template>
  <section class="airdrops-page">
    <AirdropHeader :search-keyword.sync="searchKeyword" />
    <div class="d-flex align-center">
      <v-progress-circular
        v-if="loading"
        class="loader-main"
        size="50"
        width="5"
        indeterminate
      />
    </div>
    <div v-if="!loading" class="content">
      <AirdropMain
        :airdrops.sync="filteredAirdrop"
        :upcoming-airdrop="filteredUpcomingAirdrop"
        :ongoing-airdrop="filteredOngoingAirdrop"
        :ended-airdrop="filteredEndedAirdrop"
        @showItemPreview="showItemPreview"
        @showModal="openModal"
      />
      <AirdropAside
        :key="previewItem.id"
        :preview-item="previewItem"
        class="airdrops-aside airdrops-aside_desktop"
        @toggleLike="handleLikesValue($event, previewItem)"
      />
      <BRModal :is-dialog-open="showModal" @close="closeModal">
        <template #content>
          <AirdropAside
            :key="previewItem.id"
            :preview-item="previewItem"
            class="airdrops-aside airdrops-aside_mobile"
            @toggleLike="handleLikesValue($event, previewItem)"
          />
        </template>
      </BRModal>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
import AirdropHeader from '@/components/AirdropHeader'
import AirdropMain from '@/components/AirdropMain'
import AirdropAside from '@/components/AirdropAside'
import BRModal from '@ui/BRModal.vue'

export default {
  name: 'Airdrop',
  components: {
    BRModal,
    AirdropHeader,
    AirdropMain,
    AirdropAside
  },
  data() {
    return {
      loading: false,
      airdrops: [],
      filteredAirdrop: [],
      upcomingAirdrop: [],
      filteredUpcomingAirdrop: [],
      ongoingAirdrop: [],
      filteredOngoingAirdrop: [],
      endedAirdrop: [],
      filteredEndedAirdrop: [],
      previewItem: {},
      keyword: '',
      showModal: false
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    searchKeyword: {
      get() {
        return this.keyword
      },
      set(value) {
        this.keyword = value
      }
    }
  },
  watch: {
    searchKeyword() {
      if (this.searchTimer) { clearTimeout(this.searchTimer) }
      this.searchTimer = setTimeout(() => {
        this.filterAirdrops()
        this.searchTimer = null
      }, 500)
    }
  },
  mounted() {
    this.getAirdrops()
  },
  methods: {
    async getAirdrops() {
      this.loading = true
      const airdropIds = this.$contentful.airdrops.map(airdrop => airdrop.id)
      const { data } = await api.likes.getLikes(airdropIds.join(','), 'airdrop')

      this.airdrops = this.$contentful.airdrops.map(airdrop => {
        airdrop.likesAmount = data.find(like => like.itemId === airdrop.id)?.likesAmount || 0
        airdrop.isLiked = data.find(like => like.itemId === airdrop.id)?.isLiked || false
        return airdrop
      })

      this.airdrops.forEach(airdrop => {
        const currentDate = new Date()
        const airdropStartDate = new Date(airdrop.dateStart)
        const airdropEndDate = new Date(airdrop.dateEnd)

        if (airdropStartDate > currentDate) {
          this.upcomingAirdrop = [...this.upcomingAirdrop, airdrop]
        }

        if (airdropStartDate <= currentDate && airdropEndDate >= currentDate) {
          this.ongoingAirdrop = [...this.ongoingAirdrop, airdrop]
        }

        if (airdropEndDate < currentDate) {
          this.endedAirdrop = [...this.endedAirdrop, airdrop]
        }
      })

      this.filterAirdrops()
      const [firstItem] = this.airdrops
      this.previewItem = firstItem || {}
      this.loading = false
    },
    filterAirdrops() {
      this.filteredAirdrop = this.airdrops.filter(airdrop => {
        return airdrop.title.toLowerCase().includes(this.searchKeyword.toLowerCase())
      })

      this.filteredUpcomingAirdrop = this.upcomingAirdrop.filter(airdrop => {
        return airdrop.title.toLowerCase().includes(this.searchKeyword.toLowerCase())
      })

      this.filteredOngoingAirdrop = this.ongoingAirdrop.filter(airdrop => {
        return airdrop.title.toLowerCase().includes(this.searchKeyword.toLowerCase())
      })

      this.filteredEndedAirdrop = this.endedAirdrop.filter(airdrop => {
        return airdrop.title.toLowerCase().includes(this.searchKeyword.toLowerCase())
      })
    },
    showItemPreview(item) {
      this.previewItem = item
    },
    handleLikesValue(value, airdrop) {
      airdrop.isLiked = value.isLiked
      airdrop.likesAmount = value.likesAmount
    },
    closeModal() {
      this.showModal = false
    },
    openModal(value) {
      this.showModal = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.airdrops-page {
  @include container;

  padding-bottom: 20px;

  @include breakpoint(small) {
    padding-bottom: 30px;
  }
}

.content {
  display: flex;
}

.airdrops-aside_desktop {
  display: none;

  @include breakpoint(large) {
    display: block;
  }
}

.airdrops-aside_mobile {
  display: block;
  padding: 0;

  @include breakpoint(large) {
    display: none;
  }
}
</style>
