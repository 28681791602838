<template>
  <v-dialog
    v-model="isOpen"
    :content-class="`${contentClass}`"
    :fullscreen="fullscreen"
    :max-width="modalWidth"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <div v-if="$slots.title || $slots.description || isShowCloseButton" class="modal-header">
      <h2 class="modal-title">
        <slot name="title" />
      </h2>
      <div class="modal-description">
        <slot name="description" />
      </div>
      <button
        v-if="isShowCloseButton"
        class="close-btn"
        @click="$emit('close')"
      >
        <v-icon class="close-icon" size="24">
          mdi-close
        </v-icon>
      </button>
    </div>
    <slot name="content" />
    <slot name="actions" />
  </v-dialog>
</template>

<script>
export default {
  name: 'BRModal',
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true
    },
    modalWidth: {
      type: String,
      required: false,
      default: '675px'
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },
    contentClass: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    isShowCloseButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isOpen: this.isDialogOpen
    }
  },
  watch: {
    isDialogOpen(value) {
      this.isOpen = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

::v-deep .v-dialog {
  position: relative;
  border-radius: 10px;
  padding: 22px 24px;
  background-color: var(--content-bg-color);

  @include breakpoint(medium) {
    padding: 36px 42px;
  }
}

.close-btn {
  position: absolute;
  right: 24px;
  top: 22px;

  .close-icon {
    color: var(--theme-switch-icon-color);
  }
}

.modal-title {
  margin-bottom: 30px;
  font-size: var(--font-h1);
  color: var(--text-color);
}
</style>
