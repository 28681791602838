<template>
  <v-chip
    :color="color"
    text-color="var(--button-text-color)"
    :class="['blockreviews-chip', { 'uppercase' : uppercase }]"
  >
    {{ value }}
  </v-chip>
</template>

<script>
export default {
  name: 'BRChip',
  props: {
    value: {
      type: String,
      required: true,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: 'var(--link-color)'
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.blockreviews-chip::v-deep.v-chip.v-size--default {
  display: flex;
  justify-content: center;
  border-radius: var(--border-radius-small);
  padding: 3px 6px;
  height: 20px;
  min-width: 58px;

  @include breakpoint-reverse(small) {
    padding: 4px 5px;
    height: 17px;
  }
}

.blockreviews-chip::v-deep.v-chip .v-chip__content {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xs);
  text-transform: capitalize;
}

.uppercase::v-deep.v-chip .v-chip__content {
  text-transform: uppercase;
}
</style>
